.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
.button {
  background-color: transparent;
  line-height: 1.47058824;
  cursor: pointer;
  color: #fff;
  padding: 30px 40px;
  font-weight: 500;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  border: 2px solid #A2D9D1;
}
@media (max-width: 1199px) {
  .button {
    padding: 15px 20px;
  }
}
body.c1 .button {
  color: #A2D9D1;
  border-color: #A2D9D1;
}
body.c2 .button {
  color: #AC7979;
  border-color: #AC7979;
}
body.c3 .button {
  color: #B9B927;
  border-color: #B9B927;
}
body.c4 .button {
  color: #DD8B3D;
  border-color: #DD8B3D;
}
body.c5 .button {
  color: #919FC4;
  border-color: #919FC4;
}
body.c6 .button {
  color: #AD9B79;
  border-color: #AD9B79;
}
.button:hover,
.button:focus {
  border-color: #000000 !important;
  color: #000000 !important;
}
.button:active {
  border-color: #000000;
  color: #000000;
}
div.navi {
  float: left;
}
div.navi > .item {
  float: left;
}
div.navi > .item > .menu {
  display: block;
}
@media (min-width: 1200px) {
  .togglenavigation,
  .mobile-navigation {
    display: none;
  }
}
div.sub1 {
  display: flex;
  flex-direction: column;
  margin-left: 30px;
}
div.sub1 > .item > .menu {
  font-size: clamp(14px, 1.2vw, 20px);
  line-height: 1.65;
}
div.sub1 > .item > .menu:hover,
div.sub1 > .item > .menu:focus {
  font-weight: 500;
}
div.sub1 > .item > .menu.path {
  font-weight: 500;
}
div.sub2 {
  display: flex;
  flex-direction: column;
}
div.sub2 > .item {
  text-align: right;
  margin-bottom: 5px;
}
div.sub2 > .item.exit {
  margin-bottom: 0;
}
div.sub2 > .item > .menu {
  font-size: clamp(16px, 1.5vw, 25px);
  line-height: 1.2;
  position: relative;
  padding-top: 2px;
  padding-right: 22px;
  padding-left: 22px;
  box-sizing: border-box;
  transition: all 0.6s cubic-bezier(0.05, 0.8, 0.5, 1);
  z-index: 2;
}
div.sub2 > .item > .menu:after {
  content: '';
  position: absolute;
  z-index: -1;
  right: 0;
  top: 0;
  bottom: 0;
  width: 7px;
  height: 100%;
  background-color: #A2D9D1;
  transition: all 0.6s cubic-bezier(0.05, 0.8, 0.5, 1);
}
div.sub2 > .item > .menu:hover,
div.sub2 > .item > .menu:focus {
  color: #FFFFFF;
}
div.sub2 > .item > .menu:hover:after,
div.sub2 > .item > .menu:focus:after {
  width: 100%;
}
div.sub2 > .item > .menu.path {
  color: #FFFFFF;
}
div.sub2 > .item > .menu.path:after {
  width: 100%;
}
div.sub2 > .item.c1 > .menu:after {
  background-color: #A2D9D1;
}
div.sub2 > .item.c2 > .menu:after {
  background-color: #AC7979;
}
div.sub2 > .item.c3 > .menu:after {
  background-color: #B9B927;
}
div.sub2 > .item.c4 > .menu:after {
  background-color: #DD8B3D;
}
div.sub2 > .item.c5 > .menu:after {
  background-color: #919FC4;
}
div.sub2 > .item.c6 > .menu:after {
  background-color: #AD9B79;
}
#home {
  width: clamp(213px, 17vw, 572px);
  margin-right: min(6vw, 30px);
  margin-top: 0;
  margin-bottom: 65px;
}
@supports not (width: clamp(213px, 17vw, 572px)) {
  #home {
    width: 17vw;
    min-width: 213px;
  }
}
#head {
  margin-top: 225px;
}
@media (max-width: 1199px) {
  #head {
    margin-top: 225px;
  }
}
@media (max-width: 767px) {
  #head {
    margin-top: 120px;
  }
}
.navigation {
  display: flex;
  flex-direction: row;
  padding: 60px 0px 60px min(2vw, 50px);
  box-sizing: border-box;
  border-left: 1px solid #000000;
}
.container {
  width: 50vw;
}
main {
  margin-top: 330px;
}
.section--multimood {
  width: calc(50vw - 1px);
  height: 100vh;
  position: fixed;
}
.section--slider {
  width: calc(50vw - 1px);
  height: 100vh;
  position: fixed;
}
#edit .section--slider {
  position: absolute;
}
.wrapper {
  font-size: min(2vw, 38px);
  line-height: 1.44736842;
}
h1,
h2 {
  font-size: min(5vw, 85px);
  line-height: 1.17647059;
}
.section--one .area {
  padding-right: 11.45038168%;
  box-sizing: border-box;
}
/*# sourceMappingURL=./screen-large.css.map */